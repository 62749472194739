
import { Box, Typography, Drawer, Toolbar, List, ListItem, ListItemIcon, ListItemButton, ListItemText, Collapse, Divider, AppBar, Grid, Paper, Menu, Fade, MenuItem, IconButton } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { ExpandLess, ExpandMore, ChevronLeft, ChevronRight, } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MYREDFOLDER from "../../../../assets/MyRedFolderLogo.png";
import close from "../../../../assets/icons/close-white-icon.svg";
import '../../../../containers/App/App.css';
import constants from '../../../../config/config';
import { useTranslation } from 'react-i18next';
export default function TopBar() {

  const [submenuActive, setSubmenuActive] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorE3, setAnchorE3] = React.useState(null);
  const [anchorE4, setAnchorE4] = React.useState(null);
  const [showSearchInput, setShowSearchInput] = React.useState(false);
  const anchorElopen = Boolean(anchorEl);
  const anchorE2open = Boolean(anchorE2);
  const anchorE3open = Boolean(anchorE3);
  const anchorE4open = Boolean(anchorE4);
  const navigate = useNavigate();
  const [active, setActiveTab] = React.useState("")
  const location = useLocation();
  const popoverRef = useRef(null);
  const [sidemenuActive, setsidemenuActive] = useState();
  const { t } = useTranslation('common');

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);
  useEffect(() => {
    const initialResizeEvent = new Event('resize');
    window.dispatchEvent(initialResizeEvent);
  }, [location.pathname]);

  useEffect(() => {

    const pathname = location.pathname;
    if (pathname === '/home') setActiveTab('Home');
    else if (pathname.startsWith('/aboutUs')) setActiveTab("About Us");
    else if (pathname.startsWith('/ourServices')) setActiveTab("Our Services");
    else if (pathname === '/pricing') setActiveTab("Pricing");
    else if (pathname.startsWith('/knowledgeBase')) setActiveTab("Knowledge Base");
    else if  (pathname === '/')  setActiveTab('Home')
  }, [location.pathname]);

  useEffect(() => {

    if (anchorE4) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };


  }, [anchorE4]);

  const pages = [
    {
      menuTitle: "Home",
      pageURL: "/home",
      isPublic: true,
      isdropDown: false,
      isEnable:true
    },
    {
      menuTitle: "About Us",
      pageURL: "#",
      isPublic: true,
      isdropDown: true,
      isEnable:true,
      anchorTag: 'anchorEl',
      open: 'anchorElopen',
      subMenuOption: [
        {
          menuTitle: "Our History",
          pageURL: "/aboutUs/our-history",
          isPublic: true,
          isEnable:true
        },
        {
          menuTitle: "Meet the Team",
          pageURL: "/aboutUs/our-team",
          isPublic: true,
          isEnable:true
        },
        {
          menuTitle: "Our Values & Mission",
          pageURL: "/aboutUs/our-values",
          isPublic: true,
          isEnable:true
        },
        {
          menuTitle: "Why Our Roles Matters",
          pageURL: "/aboutUs/our-roles",
          isPublic: true,
          isEnable:true
        },
        {
          menuTitle: "Events Calender",
          pageURL: "/aboutUs/events",
          isPublic: true,
          isEnable:true
        },
        {
          menuTitle: "Contact Us",
          pageURL: "/aboutUs/contact-us",
          isPublic: true,
          isEnable: true
        }
      ]
    },
    {
      menuTitle: "Our Services",
      pageURL: "#",
      isPublic: true,
      isdropDown: true,
      isEnable:true,
      anchorTag: 'anchorE2',
      open: 'anchorE2open',
      subMenuOption: [
        {
          menuTitle: "MYREDFOLDER© APP",
          pageURL: "/ourServices/my-mrf-app",
          isPublic: true,
          isEnable:true,
        },
        {
          menuTitle: "MYREDFOLDER® Plan", //my family plan
          pageURL:'/ourServices/my-mrf-plan',
          isPublic: true,
          isEnable:true,
        },
          {
            menuTitle: "Go Kits / Supply Kits",
            pageURL: "/ourServices/supply-kits",
            isPublic: true,
            isEnable:true,
          },
          // subMenuOptionone: [
            // {
            //   menuTitle: "Risks based on My Location",
            //   pageURL: "/ourServices/risk-by-location",
            //   isPublic: true,
            // },
            // {
            //   menuTitle: "Safety of Family Members",
            //   pageURL: "/ourServices/safty",
            //   isPublic: true,
            // },
            // {
            //   menuTitle: "Loss of Property",
            //   pageURL: "/ourServices/loss-of-property",
            //   isPublic: true,
            // },
            // {
            //   menuTitle: "Lack of Supplies",
            //   pageURL: "/ourServices/loss-of-supply",
            //   isPublic: true,
            // }
          // ]
        // }
      ]
    },
    {
      menuTitle: "Pricing",
      pageURL: "/pricing",
      isPublic: true,
      isdropDown: false,
    },
    {
      menuTitle: "Knowledge Base",
      pageURL: "#",
      isPublic: true,
      isEnable:true,
      isdropDown: true,
      anchorTag: 'anchorE3',
      open: 'anchorE3open',
      subMenuOption: [
        {
          menuTitle:'Disaster Resource Library',
          pageURL: "/knowledgeBase/risk-by-location",
          isPublic: true,//make true,
          isEnable:true,
        },
        {
          menuTitle: "Blog",
          pageURL: "/knowledgeBase/blog",
          isPublic: false,//make true
          isEnable:true,
        },
        {
          menuTitle: "FAQ",
          pageURL: "/knowledgeBase/faq",
          isPublic: false,//make true
          isEnable:true,
        },
        {
          menuTitle:'MYREDFOLDER® Tribe',
          pageURL: "/knowledgeBase/myredfolder-tribe",
          isPublic: false,//make true
          isEnable:true,
        },
      ]
    }
  ];

  const handleOutsideClick = (event) => {
    if (!popoverRef.current?.contains(event.target)) {
      setAnchorE2(null);
      setAnchorE4(null)
      setSubmenuActive(null)
    }
  };

  const handleClick = (event, page) => {
    if (page.anchorTag === 'anchorEl') {
      setAnchorEl(event.currentTarget);
    }
    else if (page.anchorTag === 'anchorE2') {
      setAnchorE2(event.currentTarget);
    }
    else if (page.anchorTag === 'anchorE3') {
      setAnchorE3(event.currentTarget);
    }
    else if (page.anchorTag === 'anchorE4') {
      setAnchorE4(event.currentTarget);
      // setSubmenuActive("MYREDFOLDER® Plan")
    }
  };
  const handleClose = (page) => {

    if (page.anchorTag === 'anchorEl') {
      setAnchorEl(null);
    }
    else if (page.anchorTag === 'anchorE2') {
      setAnchorE2(null);
    }
    else if (page.anchorTag === 'anchorE3') {
      setAnchorE3(null);
    }
    else if (page.anchorTag === 'anchorE4') {
      setAnchorE4(null);
      setAnchorE2(null);
      setSubmenuActive(null);
    }
  };
  const handleMenuClick = (menu, event) => {
    // navigate(menu.pageURL)
    // setActive(menu.menuTitle);
    // 

    if (menu.menuTitle === "Home") {
      navigate(menu.pageURL)
      // setActive(menu.menuTitle);

      setAnchorEl(null);
      setAnchorE2(null);
      setAnchorE3(null);
      setAnchorE4(null);

    }

    else if (menu.menuTitle === "About Us") {

      if (constants.ENVIRONMENT !=="STAGING"){
         navigate(menu.pageURL)
      // setActive(menu.menuTitle);

      setAnchorEl(event.currentTarget);
      setAnchorE2(null);
      setAnchorE3(null);
      }
      navigate(menu.pageURL)
      // setActive(menu.menuTitle);

      setAnchorEl(event.currentTarget);
      setAnchorE2(null);
      setAnchorE3(null);
    }
    else if (menu.menuTitle === "Our Services") {
      if (constants.ENVIRONMENT !=="STAGING"){
         navigate(menu.pageURL)
      // setActive(menu.menuTitle);

      setAnchorE2(event.currentTarget);
      setAnchorEl(null);
      setAnchorE3(null);
      setAnchorE4(null);
      setSubmenuActive(null)
      }

      // navigate(menu.pageURL)
      // setActive(menu.menuTitle);

      setAnchorE2(event.currentTarget);
      setAnchorEl(null);
      setAnchorE3(null);
      setAnchorE4(null);
      setSubmenuActive(null)

    }
    else if (menu.menuTitle === "Pricing") {
      if (constants.ENVIRONMENT !=="STAGING"){
        navigate(menu.pageURL)
      // setActive(menu.menuTitle);

      setAnchorEl(null);
      setAnchorE2(null);
      setAnchorE3(null);
      setAnchorE4(null);
      
      } 
      navigate(menu.pageURL)
      // setActive(menu.menuTitle);

      setAnchorEl(null);
      setAnchorE2(null);
      setAnchorE3(null);
      setAnchorE4(null);
    }

    else if (menu.menuTitle === "Knowledge Base") {
      if (constants.ENVIRONMENT !=="STAGING"){
       navigate(menu.pageURL)
      // setActive(menu.menuTitle);

      setAnchorE3(event.currentTarget);
      setAnchorE2(null);
      setAnchorEl(null);
      }
      // navigate(menu.pageURL)
      // setActive(menu.menuTitle);

      setAnchorE3(event.currentTarget);
      setAnchorE2(null);
      setAnchorEl(null);
    }
  };


  const sidenavItem = [
    {
      id: 1,
      text: "Home",
      path: "/home",
      isEnable:true

    },
    {
      id: 2,
      text: "About Us",
      isEnable:true,
      sublinks: [
        {
          text: "Our History",
          path: "/aboutUs/our-history",
          isEnable:true

        }, {
          text: "Meet the Team",
          path: "/aboutUs/our-team",
          isEnable:true
        },
        {
          text: "Our Values & Mission",
          path: "/aboutUs/our-values",
          isEnable:true
        },
        {
          text: "Why Our Roles Matters",
          path: "/aboutUs/our-roles",
          isEnable:true
        },
        {
          text: "Events Calender",
          path: "/aboutUs/events",
          isEnable:true

        },
        {
          text: "Contact Us",
          path: "/aboutUs/contact-us",
          isEnable:true

        }
      ]
    },
    {
      id: 3,
      text: "Our Services",
      isEnable:true,
      sublinks: [
        {sublinkid:1,
          isEnable:true,
          text: "MYREDFOLDER© APP",
          path: "/ourServices/my-mrf-app",
        },
        {  sublinkid:2,
          isEnable:true,
          text: "MYREDFOLDER® Plan",
          path:'/ourServices/my-mrf-plan'
        },
        {sublinkid:3,
          isEnable:true,
          text: "Go Kits / Supply Kits",
          path: "/ourServices/supply-kits",
        },
        // innerlinks: [
            // {
            //   text: "Risks based on My Location",
            //   path: "/ourServices/risk-by-location",

            // },

          //   {
          //     text: "Safety of Family Members",
          //     path: "/ourServices/safty",
          //   },
          //   {
          //     text: "Loss of Property",
          //     path: "/ourServices/loss-of-property",
          //   },
          //   {
          //     text: "Lack of Supplies",
          //     path: "/ourServices/loss-of-supply",
          //   }
          // ]
        // },
      ]
    },
    {
      id: 4,
      text: "Pricing",
      path: "/pricing",
      isEnable:true

    },
    {
      id: 5,
      text: "Knowledge Base",
      isEnable:true,
      sublinks: [
        {
          text:"Disaster Resource Library",
          path:'/knowledgeBase/risk-by-location',
          isEnable:true
       
        },
        {
          text: "Blog",
          path: "/knowledgeBase/blog",
          isEnable:true
        }, {
          text: "FAQ",
          path: "/knowledgeBase/faq",
          isEnable:true

        },
        {
          text:"MYREDFOLDER® Tribe",
          path:'/knowledgeBase/myredfolder-tribe',
          isEnable:true
        }
      ]
    }

  ]
  const handlesidemenuclick =(text)=>{
    
    setsidemenuActive(text)
  }
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    marginLeft: "16px"
  }));


  const [open, setOpen] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setItemId("")
  };
  const handlecloseDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setItemId("")
    setOpen(false)
  };


  const handleClickMenu = () => {
    setOpen(!open);
    
  };

  const [itemId, setItemId] = useState('');
  const [subitemId, setsubItemId] = useState('');

  const rendrSublinks = (subLinks, listId) => {

    const rendrinnerlinks = (innerLinks, Id) => {
      { console.log(innerLinks, 'the inner') }
      return (
        <>
          <Collapse in={open} timeout="auto" unmountOnExit >
            <List component="div"  sx={{backgroundColor:"#5d5e61"}} >
              {innerLinks?.map((text) => (
                <ListItem button key={text.text}
                onClick={() => {
                  navigate(text.path);
                  setItemId("");
                  setIsDrawerOpen(false)
                }}
                >
                  <ListItemText primary={text.text} sx={{
                    pl: 2, color: "white", ":hover": {
                      color: "#FF5252",
                      opacity:1
                    },
                  }} />

                </ListItem>

              ))}
            </List>
          </Collapse>

        </>
      );
    }
    { console.log(subLinks, 'the sub') }
    return (
      <>
        <Collapse in={itemId === listId}  timeout="auto" unmountOnExit>
          <List component="div"  sx={{backgroundColor:"#5d5e61"}}>


            {subLinks?.map((text) => (
              <>
                <ListItem button key={text.subid}
                  onClick={() => {
                    handlesidemenuclick(text.subid)
                    if (text.innerlinks) {
                      if(text.isEnable){

                      
                      setsubItemId(text.sublinkid);
                      handleClickMenu()
                      if (text.sublinkid === subitemId) {
                        setsubItemId(0)

                      }
                    }
                    else{
                      setIsDrawerOpen(false) 
                    }
                    }
                    else {
                      if(text.isEnable){ 
                       navigate(text.path)
                       setItemId("")
                       setIsDrawerOpen(false)
                        }
                          else{
                        setIsDrawerOpen(false)
                          }
                    
                    
                    }
                
                  }
 
                  }
                   
                  >
                 <ListItemText primary={text.text} sx={{
                    pl: "10px", color: "white", ":hover": {
                      color: "red"
                    },
                  }} />
               
                  
                  {text.innerlinks && 

                    (subitemId === text.sublinkid ? <ExpandLess sx={{color:"white"}} /> : <ExpandMore  sx={{color:"white"}}/>)
                  }
                </ListItem>

                {text.innerlinks && rendrinnerlinks(text.innerlinks, text.sublinkid)}
              </>
            ))}
          </List>
         
        </Collapse>

      </>
    );
  }

  return (
    <AppBar position="static" style={{ background: "white", padding: "0px" }}>
      
      {console.log(screenWidth, "screenWidth ------------------")}

      <Box className="header-navbar-container">
      <Toolbar disableGutters className='bigsize'>
      {/* <div className="container-topbar">  */}

        <Grid container >
          {/* <Grid item xs={1} sm={1} md={1} lg={1} ></Grid> */}
          <Grid item container xs={12} sm={12} md={12} lg={12} sx={{justifyContent:"space-between"}}>

            <Grid >
              <img src={MYREDFOLDER} alt="myredfolderlogo" id="mrflogo"
                // onClick={() => {
                //   navigate("/home")
                //   localStorage.setItem("menuTitle", "Home");
                // }} 
                style={{ marginRight: 10, marginTop: "6px" }} />
            </Grid>
            <Grid  sx={{mr:"-16px"}}>
              <Box
                sx={{

                  display: "flex",
                  alignItems: "center",
                  gap: '10px',
                  paddingLeft: 0,
                
                }}
              >
                {pages?.map((page) => (
                  <div>
                    <MenuItem 
                      key={page}
                      onClick={(e) => {
                        handleMenuClick(page, e);

                      }}
                      className={
                        active === page.menuTitle
                          ? "menu-top-bar active-menu"
                          : "menu-top-bar"
                      }
                    >
                      <Typography textAlign="center" className="primary-font-medium">
                     {/* { t(`${page.menuTitle}`)} */}
                        {page.menuTitle}
                      </Typography>
                    </MenuItem>
                    {page.isdropDown && (
                      <div>
                        <Menu
                          id="fade-menu"
                          MenuListProps={{
                            'aria-labelledby': 'fade-button',
                          }}
                          anchorEl={eval(page.anchorTag)}
                          open={eval(page.open)}
                          onClose={() => { handleClose(page) }}
                          TransitionComponent={Fade}
                          className="submenu "
                        >
                          {page?.subMenuOption?.map((submenu) => (
                            <>
                              <MenuItem
                                className={
                                  submenuActive === submenu.menuTitle
                                    ? "active-menu"
                                    : "submenu-header"
                                }
                                onClick={(e) => {
                                  // if (submenu.menuTitle === "MYREDFOLDER® Plan") {
                                  //   setAnchorE4(e.currentTarget);
                                  //   // setSubmenuActive("MYREDFOLDER® Plan")
                                  // } else {
                                    handleClose(page);
                                    if (constants.ENVIRONMENT !=="STAGING"){
                                      navigate(submenu.pageURL);
                                    }

                                   else if(submenu.isEnable){
                                      navigate(submenu.pageURL);
                                    }
                                   
                                  // }

                                }}
                                sx={{ minWidth: "183px" }}
                              >
                                <Typography className="primary-font-medium-1" style={{ display: 'flex' }}>{submenu?.menuTitle} </Typography></MenuItem>
                              {/* {submenu.menuTitle === "MYREDFOLDER® Plan" && (
                                <div ref={popoverRef} >
                                  <Menu
                                    id="fade-menu"
                                    MenuListProps={{
                                      'aria-labelledby': 'fade-button',
                                    }}
                                    anchorEl={anchorE4}
                                    open={anchorE4open}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                    TransitionComponent={Fade}

                                    className="submenu alignment-style "
                                  >
                                    {submenu?.subMenuOptionone?.map((submenuone) => (
                                      <div >
                                        <MenuItem
                                          className="submenu-header"
                                        onClick={() => {
                                          handleClose({ anchorTag: 'anchorE4' });
                                          navigate(submenuone.pageURL);
                                          // setActive(page.menuTitle);
                                        }}
                                        >
                                          <Typography
                                            className="primary-font-medium-1"

                                          >{submenuone.menuTitle}</Typography>
                                        </MenuItem>
                                      </div>

                                      // <MenuItem 
                                      // sx={{  borderBottom: "none "}} 
                                      //   className="submenu-header"
                                      //   onClick={()=>{
                                      //     handleClose( {anchorTag: 'anchorE4'});
                                      //     navigate('/ourServices/safty'); 
                                      //     // setActive(page.menuTitle);
                                      //   }}
                                      // >
                                      //     <Typography className="primary-font-medium-1">Safety of Family Members</Typography>
                                      // </MenuItem>
                                      // <MenuItem 
                                      //   className="submenu-header"
                                      //   sx={{  borderBottom: "none"}} 
                                      //   onClick={()=>{
                                      //     handleClose( {anchorTag: 'anchorE4'});
                                      //     navigate('/ourServices/loss-of-property'); 
                                      //     // setActive(page.menuTitle);
                                      //   }}
                                      // >
                                      //     <Typography className="primary-font-medium-1">Loss of Property</Typography>
                                      // </MenuItem>
                                      // <MenuItem 
                                      // sx={{ color: 'black !important',
                                      // textAlign: 'left',
                                      // paddingTop: '10px !important',
                                      // paddingBottom: '2px !important',
                                      // ":hover":{
                                      //   color: '#ff3535 !important',
                                      //   opacity: 1,
                                      //   backgroundColor: 'white !important',
                                      // }}}
                                      //    onClick={()=>{
                                      //     handleClose( {anchorTag: 'anchorE4'});
                                      //     navigate('/ourServices/loss-of-supply'); 
                                      //     // setActive(page.menuTitle);
                                      //   }}
                                      // >
                                      //     <Typography className="primary-font-medium-1">Lack of Supplies</Typography>
                                      // </MenuItem>
                                    ))}
                                  </Menu>
                                </div>
                              )} */}
                            </>
                          ))
                          }
                        </Menu>
                      </div>
                    )
                    }
                  </div>
                ))}
              </Box>
            </Grid>
            {/* <Grid xs={1} sm={1} md={1} sx={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
              {!showSearchInput && (
              <div onClick={()=>setShowSearchInput(true)}><img src={SearchIcon} alt="search-icon" id="search-icon" /></div>
            )}
            {showSearchInput && (
              <div className="header-search-holder">
                <Input placeholder="Search" slotProps={{ input: { className: 'my-input' } }}  />
                <Button className="go-btn">Go</Button>
                <div className="search-close" onClick={()=>setShowSearchInput(false)}><CloseIcon color="#FF3535"/></div>
              </div>
            )}

            </Grid> */}
          </Grid>
          {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
        </Grid>
{/* </div> */}
      </Toolbar>





      <Toolbar disableGutters className='smallsize'>
        <Grid container  >
          <Grid sx={{ my: 'auto' }} item xs={1} sm={1} md={1} lg={1} >
            <Grid    sx={{
                  ml:"-5px"
                }} >

              <IconButton
                edge="center"
                color="black"
                aria-label="menu"
                onClick={toggleDrawer}
              >
                <MenuIcon />
              </IconButton>

            </Grid>
          </Grid>
          <Grid item container xs={11} md={11} lg={11}>

            <Grid xs={6} md={6} lg={6} >
              <img src={MYREDFOLDER} alt="myredfolderlogo" id="mrflogo"
                // onClick={() => {
                //   navigate("/home")

                // }}
                style={{ marginRight: 10, marginTop: "6px" }} />
            </Grid>

          </Grid>

          {/* <Grid item xs={1} sm={1} md={1} lg={1} ></Grid> */}


          <Grid>
            <Drawer

              anchor="left"
             
              open={isDrawerOpen} onClose={toggleDrawer}
            >
              <DrawerHeader>
                <img src={close} onClick={handlecloseDrawer} alt='close' height={20} width={20} />
              </DrawerHeader>



              <List sx={{pb:"0px"}}>
              
                {sidenavItem?.map((text, index) => (
                  <>
                    <ListItem
                      key={text.id}
                      
                      onClick={() => {
                       
                        if (text.sublinks) {

                        if(text.isEnable){
                          setActiveTab("")
                          setItemId(text.id);

                          if (text.id === itemId) {
                            setItemId(0)

                          }
                        }
                        else{
                          setIsDrawerOpen(!isDrawerOpen); 
                        }

                        }
                        else {
                          if(text.isEnable){ 
                           navigate(text.path)
                           setIsDrawerOpen(!isDrawerOpen);
                          }
    
                        
                        
                       
                        }
                      }}
                      className={
                        active === text.text
                          ? "side-menu"
                          : "side-bar"
                      } 
                      sx={{
                      
                      }}>
                      <ListItemText primary={text.text} sx={{pl:"10px"}}
                      //  className={
                      //   active === text.text
                      //     ? "side-menu"
                      //     : "side-bar"
                      // } 
                      />
                {text?.sublinks &&

                        (itemId === text.id && text.isEnable ? <ExpandLess /> : <ExpandMore />)
                      }

                    </ListItem>
                   

                    {text.sublinks && text.isEnable? rendrSublinks(text.sublinks, text.id):""}
                   
                  </>
                ))}

              </List>
            </Drawer>
          </Grid>
        </Grid>

      </Toolbar>
</Box>


    </AppBar>
  );
}



