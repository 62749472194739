import * as React from "react";
import './styles/Services.css';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions, Grid, Tooltip } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
// import Carousel from "react-multi-carousel";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import './styles/slickSlider.css';
// import "react-multi-carousel/lib/styles.css";
import rightarrow from "../../../../assets/icons/right-arrow-blue-icon.svg";
import leftarrow from "../../../../assets/icons/left-arrow-blue-icon.svg";
import location from '../../../../assets/icons/location-dot.svg';
import calendar from '../../../../assets/icons/calendar-days.svg';
import { useNavigate } from "react-router-dom";
import { useState, useEffect  } from "react";
import { width } from "@mui/system";

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}
const Events = () => {

  
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const[currentPage, setCurrentPage] =useState();
  const [currentSlide, setCurrentSlide] = useState(0);

  const remainingMonths = (data) => {
    // const currentDate = new Date();
    
    // const remainingMonthsData = data.filter(event => {
    //   const eventLastDate = new Date(event.date.match(/\b\w+\b\s\d+,\s\d{4}/)[0]);
    //   return eventLastDate > currentDate;
    // });
    
    // const monthsTextFormat = Object.keys(remainingMonthsData.reduce((acc, event) => {
    //   acc[event.month] = true;
    //   return acc;
    // }, {})).map(month => {
    //   const monthIndex = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].indexOf(month);
    //   return monthIndex !== -1 ? ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][monthIndex] : null;
    // }).filter(month => month !== null);
    const months = {
      "January": 0,
      "February": 1,
      "March": 2,
      "April": 3,
      "May": 4,
      "June": 5,
      "July": 6,
      "August": 7,
      "September": 8,
      "October": 9,
      "November": 10,
      "December": 11
    };
  
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();  // getMonth() returns month index (0 for January, 1 for February, etc.)
    const currentDay = currentDate.getDate();
  
    const remainingMonthsData = data.filter(event => {
      const eventMonth = months[event.month];
      const eventDay = event.lastDate;
  
      if (eventMonth > currentMonth) {
        return true;
      }
  
      if (eventMonth === currentMonth && eventDay >= currentDay) {
        return true;
      }
  
      return false;
    });
   
    console.log(remainingMonthsData, "months");
    return remainingMonthsData;
    }
    

  const servicesCard = [

    {
      title: 'International Conference: Big Data for Disaster Response ...',
      month :"February",
      lastDate : 17,
      tooltipTitle:"International Conference...",
      date:`<p>Thu, February 15, 2024, to <br>  Fri, February 17, 2024</p>`,
      location: 'Sandai, Japan',
      content: 'The Asian Development Bank (ADB) and the International Research Institute of Disaster Science (IRIDeS)...',
      navlink:"https://www.apru.org/event/international-conference-big-data-for-disaster-response-and-management-in-asia-and-the-pacific/"
    },
    {
      title: 'Crisis and Business Resilience 2024  ',
      month :"March",
      lastDate : 20,
      tooltipTitle:"Crisis and Business...",
      date:`<p>Mon, March 18, 2024, to <br>  Thu, March 20, 2024</p>`,
      location: 'Sydney ‚ Australia ',
      content: 'The 3rd Annual Crisis & Business Resilience Conference aims to provide practical ...',
      navlink:"https://www.marcusevans.com/conferences/crisisresilience/agenda"
    },
   
    {
      title: 'National Hurricane Conference  ',
      month :"March",
      lastDate : 28,
      tooltipTitle:"National Hurricane...",
     date:`<p> Mon, March 25, 2024, to <br>  Thu, March 28, 2024</p>`,
      location: ' Orlando, FL ',
      content: 'The primary goal of the National Hurricane Conference... ',
      navlink:"https://hurricanemeeting.com/"
    },
    {
      title: '2024 ASPA Annual Conference  ',
      month :"April",
      lastDate : 16,
      tooltipTitle:"2024 ASPA Annual...",
      date:`<p>Fri, April 12, 2024, to <br>  Tue, April 16, 2024</p>`,
      location: 'Minneapolis, Minnesota ',
      content: 'What does building resilient communities mean? We often think of “resilience” as ...',
      navlink:"https://www.aspanet.org/ASPA/ASPA/Events/Annual-Conference/2024-ACTracks-Theme.aspx"
    },
    {
      title: 'ICUDRPUR 2024: 18. International Conference...   ',
      month :"June",
      lastDate : 4,
      tooltipTitle:"ICUDRPUR 2024...",
      date:`<p>Mon, June 03, 2024, to <br>  Tue, June 04, 2024</p>`,
      location: ' New York, United States ',
      content: 'The International Research Conference is a federated organization dedicated to... ',
      navlink:"https://waset.org/urban-disaster-recovery-planning-and-urban-resilience-conference-in-june-2024-in-new-york"
    },
    {
      title: 'Understanding Risk Forum 2024 (UR24) ',
      month :"July",
      lastDate : 21,
      tooltipTitle:"Understanding Risk...",
      date:`<p>Sun, June 16, 2024, to <br>  Fri, June 21, 2024</p>`,
      location: 'Himeji city, Japan',
      content: 'Fostering new knowledge, innovation, and collaboration in disaster risk management...',
      navlink:"https://www.gfdrr.org/en/events"
    },
    {
      title:'International Conference on Disasters Management',
      month:"June",
      lastDate : 22,
      tooltipTitle:"International Conference on Disasters Management...",
      date:`<p> Fri, June 21, 2024, to <br> Sat, June 22, 2024 </p> `,
      location:' Los Angeles, USA',
      content:'The main purpose of International Conference on Disasters Management...',
      navlink:'https://iser.org.in/conf/index.php?id=2325706'
    },
    {
      title:'International Conference on Civil Engineering',
      month:'June',
      lastDate:22,
      tooltipTitle:"International Conference on Civil Engineering, Architecture ...",
      date:`<p> Fri, June 21, 2024, to <br> Sat, June 22, 2024 </p> `,
      location:' Miami, USA',
      content:'The main purpose of International Conference on Civil Engineering, Architecture...',
      navlink:'https://scholarsforum.org/event/index.php?id=2384136'
    },
    {
      title:'International Conference on Natural Disaster',
      month:'June',
      lastDate:25,
      tooltipTitle:" International Conference on Natural Disaster Management...",
      date:`<p>  Mon, June 24, 2024 to <br> Tue, June 25, 2024 </p> `,
      location:'Boston, USA',
      content:'The main purpose of International Conference on Natural Disaster Management...',
      navlink:'https://worldacademics.net/event/index.php?id=2385858'
    },
    {
      title:'International Conference on Geomatics, Disasters',
      month:'June',
      lastDate:28,
      tooltipTitle:"International Conference on Geomatics, Disasters Management and Planning (ICGDMP).",
      date:`<p>  Thu, June 27, 2024 to <br> Fri June 28, 2024</p> `,
      location:'Chicago, USA',
      content:'The main purpose of International Conference on Geomatics, Disasters...',
      navlink:'https://www.researchfora.net/event/index.php?id=2280165'
    },
    {
      title:'International Conference on Disasters Management',
      month:'June',
      lastDate:29,
      tooltipTitle:"International Conference on Disasters Management, Geomatics Solutions and Planning (ICDMGSP)",
      date:`<p> Fri, June 28, 2024 to <br> Sat, June 29, 2024 </p> `,
      location:'Columbus, USA',
      content:'The main purpose of International Conference on Disasters Management...',
      navlink:'https://www.wrfconference.com/event/index.php?id=2324801'
    },
    {
    title:'International Conference on Civil Engineering',
    month:'July',
    lastDate:3,
    tooltipTitle:"International Conference on Civil Engineering, Architecture and Disaster Management (ICCEADM)",
    date:`<p> Tue, July 02, 2024 to <br> Wed, July 03, 2024 </p> `,
    location:'Boston, USA',
    content:'The main purpose of International Conference on Civil Engineering, Architecture and...',
    navlink:'https://scholarsforum.org/event/index.php?id=2396854'
    },
    {
    title:'International Conference on Disaster Nursing Emergency',
    month:'July',
    lastDate:4,
    tooltipTitle:"International Conference on Disaster Nursing and Emergency Nursing Management (ICDNENM)",
    date:`<p>  Wed, July 03, 2024 to <br> Thu, July 04, 2024 </p> `,
    location:'Austin, USA',
    content:'The main purpose of International Conference on Disaster Nursing and Emergency...',
    navlink:'https://iiter.org/conf/index.php?id=2402394'
    },
    {
      title:'International Conference on Integrated Natural Disaster ',
      month:'July',
      lastDate:5,
      tooltipTitle:"International Conference on Integrated Natural Disaster Management (ICINDM)",
      date:`<p> Thu, July 04, 2024 to <br> Fri, July 05, 2024</p> `,
      location:'Columbus, USA',
      content:'The main purpose of International Conference on Integrated Natural Disaster...',
      navlink:'https://www.iirst.com/event/index.php?id=2390565'
    },
    {
      title: '12th Annual Building Resilience Conference',
      month :"July",
      lastDate : 27,
      tooltipTitle:"12th Annual Building...",
      date:`<p>Fri, July 26, 2024, to <br>  Sat, July 27, 2024</p>`,
      location: 'Washington, D.C',
      content: 'On July 26 and 27, the U.S. Chamber Foundation hosted the 12th annual Building...',
      navlink:"https://events.uschamberfoundation.org/BuildingResilienceConference_2023"
    },
    {
      title:'International Conference on Geomatics, Disasters',
      month:'August',
      lastDate:3,
      tooltipTitle:"International Conference on Geomatics, Disasters Management and Methods (ICGDMM)",
      date:`<p> Fri,Aug 02, 2024 to <br> Sat, Aug 03, 2024</p> `,
      location:'Chicago, USA',
      content:'International Conference on Geomatics, Disasters Management and Methods...',
      navlink:'https://itar.in/conf/index.php?id=2417017'
    },
    {
      title:'International Conference on Structural Engineering',
      month:'August',
      lastDate:3,
      tooltipTitle:"International Conference on Structural Engineering and Disaster Management (ICSEADM)",
      date:`<p> Fri, Aug 02, 2024 to <br> Sat, Aug 03, 2024</p> `,
      location:'New York, USA',
      content:'We are thrilled to invite you to attend the International Conference on Structural...',
      navlink:'https://www.researchfora.net/event/index.php?id=2431121'
    },
    {
      title:'International Conference on Geomatics, Disasters',
      month:'August',
      lastDate:3,
      tooltipTitle:"International Conference on Geomatics, Disasters Management and Methods (ICGDMM)",
      date:`<p> Fri, Aug 02, 2024 to <br> Sat, Aug 03, 2024</p> `,
      location:'Boston, USA',
      content:'International Conference on Geomatics, Disasters Management and Methods...',
      navlink:'https://scienceleagues.com/events/index.php?id=2435249'
    },
    {
      title:'International Conference on Disasters Management,',
      month:'September',
      lastDate:5,
      tooltipTitle:"International Conference on Disasters Management, Geomatics Solutions and Satellite Data (ICDMGSSD)",
      date:`<p> Wed, Sep 04, 2024 to <br> Thu ,Sep 05, 2024</p> `,
      location:'Boston, USA',
      content:'The systematic dissemination of the latest research findings and knowledge is crucial for the...',
      navlink:'https://scholarsforum.org/event/index.php?id=2463036'
    },
    {
      title:' International Conference on Emergency Management  ',
      month:'September',
      lastDate:6,
      tooltipTitle:"International Conference on Emergency Management and Disaster Management (ICEMDM)",
      date:`<p> Thu, Sep 05, 2024 to <br> Fri, Sep 06, 2024</p> `,
      location:'Boston, USA',
      content:'Welcome to International Conference on Emergency Management and Disaster...',
      navlink:'https://isit.org.in/event/index.php?id=2469445'
    },
    {
      title:'International Conference on Disasters Management',
      month:'September',
      lastDate:7,
      tooltipTitle:"International Conference on Disasters Management Method and Geomatics Solutions (ICDMMGS)",
      date:`<p> Fri,Sep 06, 2024 to <br> Sat ,Sep 07, 2024</p> `,
      location:'Boston, USA',
      content:'ISER is delighted to welcome you to the International Conference on Disasters Management...',
      navlink:'https://iser.org.in/conf/index.php?id=2298010'
    },
    {
      title:'International Conference on Business Continuity',
      month:'October',
      lastDate:5,
      tooltipTitle:"International Conference on Business Continuity Management and Disaster Recovery (ICBCMDR)",
      date:`<p> Fri, Oct 04, 2024 to <br> Sat, Oct 05, 2024 </p> `,
      location:'Las vegas, USA',
      content:'International Conference on Business Continuity Management and Disaster...',
      navlink:'https://itar.in/conf/index.php?id=2529639'
    },
    {
      title:'International Conference on Seismic Engineering ',
      month:'October',
      lastDate:5,
      tooltipTitle:"International Conference on Seismic Engineering and Disaster Management (ICSEDM)",
      date:`<p> Fri, Oct 04, 2024 to <br> Sat, Oct 05, 2024 </p> `,
      location:'Los Angeles, USA',
      content:'Join us at the highly anticipated International Conference on Seismic Engineering and...',
      navlink:'https://sairap.org/conf/index.php?id=2542454'
    },
    {
      title:'International Conference on Disasters Management',
      month:'October',
      lastDate:11,
      tooltipTitle:"International Conference on Disasters Management, Geomatics Solutions and Satellite Data (ICDMGSSD)",
      date:`<p> Thu, Oct 10, 2024 to <br> Fri, Oct 11, 2024 </p> `,
      location:'Phoenix, USA',
      content:'Join us for the highly anticipated International Conference on Disasters Management,..',
      navlink:'https://researchleagues.com/event/index.php?id=2547428'
    },
    {
      title:'International Conference on Civil Defense',
      month:'November',
      lastDate:5,
      tooltipTitle:"International Conference on Civil Defense and Disaster Management (ICCDDM)",
      date:`<p> Mon, Nov 04, 2024 to <br> Tue, Nov 05, 2024 </p> `,
      location:'Las vegas, USA',
      content:'ISER is delighted to welcome you to the International Conference on Civil Defense...',
      navlink:'https://iser.org.in/conf/index.php?id=2563801'
    },
    {
      title:'International Conference on Forest Fire Disaster',
      month:'November',
      lastDate:5,
      tooltipTitle:"International Conference on Forest Fire Disaster Management (ICFFDM)",
      date:`<p>  Mon, Nov 04, 2024 to <br> Tue, Nov 05, 2024</p> `,
      location:'New York, USA',
      content:'With sincere pleasure and honour, IITER welcomes you to attend International...',
      navlink:'https://iiter.org/conf/index.php?id=2564247'
    },
    {
      title:'International Conference on Geomatics, Disasters ',
      month:'November',
      lastDate:8,
      tooltipTitle:"International Conference on Geomatics, Disasters Management and Remote Sensing (ICGDMRS)",
      date:`<p> Thu, Nov 07, 2024 to <br> Fri, Nov 08, 2024 </p> `,
      location:'New York, USA',
      content:'Science Society delighted to welcome academicians, students , researchers and...',
      navlink:'https://sciencesociety.co/conf/index.php?id=2587943'
    },
    {
      title:'International Conference on Building Designs ',
      month:'December',
      lastDate:3,
      tooltipTitle:"International Conference on Building Designs and Disaster Management (ICBDDM)",
      date:`<p>  Mon, Dec 02, 2024 to <br> Tue, Dec 03, 2024  </p> `,
      location:'Las vegas, USA',
      content:'Welcome to International Conference on Building Designs and Disaster Management...',
      navlink:'https://isit.org.in/event/index.php?id=2619232'
    },
    {
      title:'International Conference on Civil Defense and Disaster',
      month:'December',
      lastDate:6,
      tooltipTitle:"International Conference on Civil Defense and Disaster Management (ICCDDM)",
      date:`<p> Thu, Dec 05, 2024 to <br> Fri, Dec 06, 2024 </p> `,
      location:'Austin, USA',
      content:'ISER is delighted to welcome you to the International Conference on Civil Defense and Disaster...',
      navlink:'https://iser.org.in/conf/index.php?id=2573798'
    },
    {
      title:'International Conference on Disaster Management',
      month:'December',
      lastDate:12,
      tooltipTitle:"International Conference on Disaster Management (ICDM)",
      date:`<p> Wed, Dec 11, 2024 to <br> Thu, Dec 12, 2024 </p> `,
      location:'California, USA',
      content:'Science Society delighted to welcome academicians, students , researchers and...',
      navlink:'https://sciencesociety.co/conf/index.php?id=2623004'
    },
    {
      title:'International Conference on Integrated Natural Disaster',
      month:'December',
      lastDate:13,
      tooltipTitle:"International Conference on Integrated Natural Disaster Management (ICINDM)",
      date:`<p> Thu, Dec 12, 2024 to <br> Fri, Dec 13, 2024 </p> `,
      location:'New York, USA',
      content:'The International Conference on Integrated Natural Disaster Management (ICINDM-24) will be held in New York...',
      navlink:'https://researchplus.co/event/index.php?id=2539331'
    },
 
  ]

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 5000, min: 2304},
      items: 5
    },
    largeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 2303, min: 1441 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const settings = {
    // dots: true,
    // slidesToScroll={1}
    // speed={500}
    // infinite: false ,
    // centerMode: true,
    arrows: true,
    lazyLoad: 'anticipated',
    centerPadding: "10px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect : true,
    beforeChange: (current, next) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
      {
        breakpoint:  1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
      {
        breakpoint:  999,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots:false,
          arrows:true
        },
      },
      {
        breakpoint:700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots:false,
          arrows:true,
          infinite:true
        },
      },
    ],
  };

  return (
    <div  className="events">
      <div  className="home-container-services">
      <Grid item xs={12} md={12} ls={12}  >
        <div style={{ paddingBottom: '50px' }}>
          <div>
            <h2
              style={{ textAlign: 'center' }}
              className="primary-font-bold-heading"
            >
              Upcoming Events
            </h2>
          </div>
          {/* <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        /> */}
          {/* <Carousel className="custom-carousel"
            responsive={responsive}
            arrows
            infinite
            style={{ paddingBottom: '20px'}}
          > */}
      
            <Slider
             {...settings}
             infinite= { remainingMonths(servicesCard).length >=3 ? true : false} 
              >
            { remainingMonths(servicesCard).map((item, i) => {
              return (
                <Item key={i} singleCard={item} />
              )
            })}
            </Slider>
       
          {/* </Carousel> */}
        </div>
      </Grid>
      </div>
     </div>
  );
};
// function ResponsiveItem({ key, singleCard }) {
//   const navigate = useNavigate();
//   return (<Grid container sx={{marginLeft:'5px', marginTop: '5px', height: '360px' }}>
//     <Card key={key} sx={{':hover': { boxShadow:5  }}} className="event-card" >
//       <CardContent style={{
//         height: '300px',
//         marginBottom: "10px"
//       }}>
//         <Typography className="primary-font-bold4 card-title" style={{ textAlign: 'left', height: '53px', paddingTop: '10px' }} >
//           {singleCard.title}
//         </Typography>
//         <hr style={{ marginTop: "20px", marginBottom: "5px" }} className="event-hr" />
//         <Box
//           component="ul"
//           className="list-component"
//           aria-labelledby="category-a"
//           sx={{ pl: 2 }}
//         >

//           <div style={{ display: 'flex' }}>
//             <img src={calendar} alt="calendar" />
//             <Typography className="primary-font-normal-4" sx={{ paddingLeft: '18px' }}>
//               <span dangerouslySetInnerHTML={{ __html: singleCard.date ? singleCard.date : "" }} />
//             </Typography>
//           </div>

//           <div style={{ display: 'flex' }}>
//             <img src={location} alt="location" />
//             <Typography className="primary-font-normal-4" sx={{ paddingLeft: '18px' }}>
//               {singleCard.location}
//             </Typography>
//           </div>

//         </Box>
//         <div style={{marginTop:'15px'}} className="card-content-wrapper primary-font-normal-4">{singleCard.content}</div>
//       </CardContent>
//       <CardActions
//         // className="read-more"
//         // style={{
//         //   height: '60px',
//         //   background: '#FAFAFB 0% 0% no-repeat padding-box'
//         // }}
//         sx={{display:"flex",justifyContent:"center",background: '#FAFAFB 0% 0% no-repeat padding-box',height: '60px'}}
//       >
//         {/* <a className="read-more  primary-font-normal-4 " target="_blank" href={singleCard.navlink}> 
//         <b>View Event Details</b>
//         </a> */}
//         <b className="read-more  primary-font-normal-4 ">View Event Details</b>
//       </CardActions>
//     </Card>
//   </Grid>
//   )
// }

function Item({ key, singleCard }) {
  const navigate = useNavigate();
  return (<Grid container item  sx={{paddingTop:'10px', paddingBottom:'10px',"&.MuiGrid-root " :{justifyContent:"center"}}}
  // sx={{height: '360px' ,"&.MuiGrid-root " :{justifyContent:"center"}}}
  >
    <Card key={key} sx={{':hover': {boxShadow:5 }}} className="event-card">
      <CardContent style={{
        height: '300px',
        marginBottom: "10px"
      }}>
        <Typography className="primary-font-bold1 card-title" style={{ textAlign: 'left', height: '53px', paddingTop: '10px' }} >
          {singleCard.title}
        </Typography>
        <hr style={{ marginTop: "20px", marginBottom: "5px" }} className="event-hr" />
        <Box
          component="ul"
          className="list-component"
          aria-labelledby="category-a"
          sx={{ pl: 2 }}
        >

          <div style={{ display: 'flex' }}>
            <img src={calendar} alt="calendar" />
            <Typography className="event-calender-font" sx={{ paddingLeft: '18px' }}>
              <span dangerouslySetInnerHTML={{ __html: singleCard.date ? singleCard.date : "" }} />
            </Typography>
          </div>

          <div style={{ display: 'flex' }}>
            <img src={location} alt="location" />
            <Typography className="event-calender-font" sx={{ paddingLeft: '18px' }}>
              {singleCard.location}
            </Typography>
          </div>

        </Box>
        <div style={{marginTop:'15px'}} className="card-content-wrapper event-calender-font">{singleCard.content}</div>
      </CardContent>
      <CardActions
        // className="read-more"
        // style={{
        //   height: '60px',
        //   background: '#FAFAFB 0% 0% no-repeat padding-box'
        // }}
        sx={{display:"flex",justifyContent:"center",background: '#FAFAFB 0% 0% no-repeat padding-box',height: '60px'}}
      >
        <a className="read-more  event-calender-font" target="_blank" href={singleCard.navlink}> 
        <b>View Event Details</b>
        </a>
        {/* <b className="read-more  event-calender-font ">View Event Details</b> */}
      </CardActions>
    </Card>
  </Grid>
  )
}

export default Events;
