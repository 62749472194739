


import * as React from 'react';
import { Alert, Box,IconButton,TextField } from '@mui/material';
import { Input } from 'antd';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
// import { Modal } from 'antd';
import { Grid } from '@mui/material';
import mfdlogo from "../../../../assets/MRF Logo.svg"
import countBtn from "../../../../assets/Count-me-in-btn.svg"
import mail from "../../../../assets/mail.png"
import close from "../../../../assets/close-icon-red.png"
import CloseIcon from '@mui/icons-material/Close';
import closeWhite from "../../../../assets/icons/close-white-icon.svg"
import offer from "../../../../assets/limitted-offer.png"
import "../../../../common/components/Layout/Header/Header.css"
import { useState } from 'react';
import { earlysignupform } from '../redux/actions';



// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 880,
//     maxHeight: 600,
//     bgcolor: 'background.paper',
//     // border: "2px solid #FF3535",
//     borderRadius: "28px",
//     p: 1,
// };

export default function InitializeModal(props) {
    const [formData, setFormdata] = useState({
        name: "",
        emailId: "",
        enquiryType: "early_signup"
    })
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        props.onClose()
        setOpen(true);

    };
    const handleClose = () => setOpen(false);
    const validateName = (name) => {
        const nameRegex = /^[a-zA-Z\s']+$/;
        return nameRegex.test(name);
    };

    const validateEmail = (email) => {
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(email);
    };
    const handleChange = (event) => {

        const { name, value } = event.target;
        if (name === "name") {
            setNameError("")
        }
        else if (name === "emailId") {
            setEmailError("")
        }
        setFormdata((prevData) => ({ ...prevData, [name]: value }));

    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const isEmailValid = validateEmail(formData.emailId);
    
        if (formData.name === "" && formData.emailId === "") {
            setNameError("This is required");
            setEmailError("This is required");
        } else if (formData.emailId === "") {
            setEmailError("This is required");
        } else if (!isEmailValid) {
            setEmailError("Please enter a valid Email");
        } else {
            console.log(formData, "1111111111");
            // setSuccessMessage('Thank you for registering your interest in MYREDFRODLER subscription. Our payments team will reach out to you within two business days to complete your subscription.');
            //  setTimeout(() => {
            //   setSuccessMessage('');
            //   props.onClose();
            //   handleClose();
            //   setFormdata({ name: '', emailId: '' });
            // }, 5000);
    
            earlysignupform(formData)
                .then((apiData) => {
                    if (apiData && apiData.status === 200) {
                        setErrorMessage('');
                        setSuccessMessage('Thank you for registering your interest in MYREDFOLDER subscription. Our payments team will reach out to you within two business days to complete your subscription.');
                       
                        setTimeout(() => {
                            setSuccessMessage('');
                            props.onClose();
                            handleClose();
                            setFormdata({ name: '', emailId: '' });
                            localStorage.setItem("offerpopup", false);
                          }, 5000);
                  
                    }
                    if (apiData && apiData.status !== 200) {
                        setErrorMessage(apiData.message);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    };
    
    return (
        <div>

            <Modal
                open={props.open}
                // onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
 <Box >
          {successMessage ? (
            <Box className="offer-form-alert-popup ">
              <Alert  variant="filled" severity="success">
                {successMessage}
              </Alert>
            </Box>
          ) :(

                <Box className="offer-popup">
                    <Box className='laptop-offer'>
                        <Grid className='laptop-offer' container sx={{
                            borderRadius: "28px",
                        }} >

                            <Grid className='laptop-offer' xs={12} md={6} sx={{ background: " transparent linear-gradient(180deg, #D93232 0%, #6C0909 100%) 0% 0% no-repeat padding-box", borderRadius: "28px 0px 0px 28px", }} >
                                <img src={offer} className='offer-logo' />
                                <Box sx={{ px: "5%" }}>
                                    <Box className="">
                                        <Typography className='primary-font-bold21' sx={{ color: "white" }}>
                                            Unlock 50% OFF Subscription Price Welcome To MYREDFOLDER®
                                        </Typography>
                                        <Typography className="primary-font-normal-20" sx={{ color: "white", mt: "13px" }}>
                                            Become part of our thriving blog community get ahead of the curve in disaster readiness
                                        </Typography>
                                    </Box>
                                    <Box className="mail-logo-container" sx={{ display: "flex", justifyContent: "center" }}>
                                        <img src={mail} className='mail-logo' />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid className='laptop-offer' xs={12} md={6} sx={{ borderBottom: "2px solid #FF3535", borderTop: "2px solid #FF3535", borderRight: "2px solid #FF3535", borderRadius: "0px 28px 28px 0px" }} >
                                <div className='laptop-offer' onClick={() => { localStorage.setItem("offerpopup", false);props.onClose(); setNameError(""); setEmailError("") }} style={{ position: "fixed", left: "94%", top: "-3.7%" }}>
                                    <Box sx={{ background: "white", width: "60px", height: "60px", border: "2px solid #D13A3C", borderRadius: "50px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <img src={close} style={{ width: "20px", height: "20px" }} />
                                      </Box>
                                        {/* <IconButton sx={{ background: "white", width: "70px", height: "70px", border: "2px solid #D13A3C", borderRadius: "50px", display: "flex", alignItems: "center", justifyContent: "center",backgroundColor:"white",color:"white" ,":hover":{background:"red",}}}>
                                        <CloseIcon sx={{color:"red",":hover":{color:"white !important"}}} />
                                        </IconButton> */}
                                </div>
                                <div style={{ margin: "0px 10%" }}>

                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <img src={mfdlogo} className='intialize-mfdlogo' />
                                        <Typography className='primary-font-normal-22 subscribe-text' sx={{ color: "#D13A3C",ml:"62px"  }}>
                                            Subscribe Now!
                                        </Typography>
                                    </Box>


                                    <Typography sx={{ my: "6px" }} className='primary-font-bold-22'>

                                        Join the MYREDFOLDER® Pre-Launch Exclusive Group Today!
                                    </Typography>
                                    <form onSubmit={handleSubmit}>
                                        <Box>
                                            <Typography className='font-normal-small error-message'>{nameError}</Typography>
                                            <label className='primary-font-medium-12'>
                                                Name<span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="name"
                                                
                                                className={`offerform  ${nameError !== '' ? 'err-input-offer' : ''}`}
                                                onChange={handleChange}
                                            />
                                        </Box>

                                        <Box>
                                            <Typography className='font-normal-small error-message'>{emailError}</Typography>
                                            <label className='primary-font-medium-12'>
                                                E-mail<span className="required-asterisk">*</span>
                                            </label>
                                            <Input
                                                type="text"
                                                name="emailId"
                                                // style={{ borderRadius: "30px !important", ml: "0% !important" }}
                                                className={`offerform  ${emailError !== '' ? 'err-input-offer' : ''}`}
                                                onChange={handleChange}
                                            />
                                            <Typography sx={{ textAlign: "center" ,mt:"2%"}} className='primary-font-normal-21'>

                                                Join the MYREDFOLDER® revolution!
                                            </Typography>
                                            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                                              

                                                {/* <img src={countBtn} onClick={handleSubmit} className='count-me-logo' /> */}
                                              
                                                <button type="submit"  style={{ border: 'none', background: 'none', padding: 0 }} onClick={handleSubmit}>
                <img src={countBtn} alt="COUNT ME IN" className='count-me-logo' style={{ cursor: 'pointer' }} />
            </button>
                                            </Box>
                                        </Box>
                                    </form>
                                </div>

                            </Grid>

                        </Grid>
                    </Box>
                    <Box className="mobile-offer" sx={{ background: " transparent linear-gradient(180deg, #D93232 0%, #6C0909 100%) 0% 0% no-repeat padding-box", borderRadius: "28px ", }} >
                        <img src={offer} className='offer-logo' />
                        <div  onClick={() => {localStorage.setItem("offerpopup", false); props.onClose();}} style={{ position: "fixed", left: "94%", top: "-3.7%" }}>
                                    <Box sx={{ background: "white", width: "40px", height: "40px", border: "2px solid #D13A3C", borderRadius: "50px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <img src={close} style={{ width: "15px", height: "15px" }} />
                                      </Box>
                        </div>
                        <Box sx={{ px: "5%" }}>
                            <Box className="">
                                <Typography className='primary-font-bold21' sx={{ color: "white" }}>
                                    Unlock 50% OFF Subscription Price Welcome To MYREDFOLDER®
                                </Typography>
                                <Typography className="primary-font-normal-20" sx={{ color: "white", mt: "7px" }}>
                                    Become part of our thriving blog community get ahead of the curve in disaster readiness
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Box className="mail-logo-container">
                                    <img src={mail} className='mail-logo' />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                                <Button className='count-me-in-btn' onClick={handleOpen}>
                                Click Here
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                </Box>
          )}
          </Box>
            </Modal>
            <div>

                <Modal
                    open={open}
                    // onClose={props.onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                 <Box >
          {successMessage ? (
            <Box className="offer-form-alert-popup ">
              <Alert variant="filled" severity="success">
                {successMessage}
              </Alert>
            </Box>
          ) :(    
                    <Box className="offer-popup" >
                        <Grid sx={{ borderRadius: "28px", border: "2px solid #FF3535", }} >
                            <div onClick={() => {localStorage.setItem("offerpopup", false); handleClose(); props.onClose(); setNameError(""); setEmailError("")}} style={{ position: "fixed", left: "92%", top: "-3.7%" }}>
                                <Box sx={{ background: "white", width: "40px", height: "40px", border: "2px solid #D13A3C", borderRadius: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {/* <div className='close-logo' style={{ width: "15px", height: "15px" }} /> */}
                                    <img src={close} style={{ width: "15px", height: "15px" }} />
                                </Box>
                            </div>
                            <div style={{ margin: "0px 10%" }}>

                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <img src={mfdlogo} className='intialize-mfdlogo' />
                                    <Typography className='primary-font-normal-22' sx={{ color: "#D13A3C", ml:"23%" }}>
                                        Subscribe Now!
                                    </Typography>
                                </Box>


                                <Typography sx={{ my: "3px" }} className='primary-font-bold-22'>

                                    Join the MYREDFOLDER® Pre-Launch Exclusive Group Today!
                                </Typography>
                                <form onSubmit={handleSubmit}>
                                    <Box>
                                        <Typography className='font-normal-small error-message'>{nameError}</Typography>
                                        <label className='primary-font-medium-12'>
                                            Name<span className="required-asterisk">*</span>
                                        </label>
                                        <Input
                                            type="text"
                                            name="name"
                                            sx={{ borderRadius: "30px !important", ml: "0% !important" }}
                                            className={`offerform  ${nameError !== '' ? 'err-input-offer' : ''}`}
                                            onChange={handleChange}
                                        />
                                    </Box>

                                    <Box>
                                        <Typography className='font-normal-small error-message'>{emailError}</Typography>
                                        <label className='primary-font-medium-12'>
                                            E-mail<span className="required-asterisk">*</span>
                                        </label>
                                        <Input
                                            type="text"
                                            name="emailId"
                                            sx={{ borderRadius: "30px !important", ml: "0% !important" }}
                                            className={`offerform  ${emailError !== '' ? 'err-input-offer' : ''}`}
                                            onChange={handleChange}
                                        />
                                        <Typography sx={{ textAlign: "center",mt:"2%" }} className='primary-font-normal-21'>

                  
                                         Join the MYREDFOLDER® revolution!
                                        </Typography>

                                        <Box sx={{ display: 'flex', justifyContent: "center" }}>
                                            {/* <Button sx={{mt:"20px !important"}} className='count-me-in-btn' onClick={handleSubmit}>
                                        COUNT ME IN
                                    </Button> */}
                                           <button type="submit"  style={{ border: 'none', background: 'none', padding: 0 }} onClick={handleSubmit}>
                <img src={countBtn} alt="COUNT ME IN" className='count-me-logo' style={{ cursor: 'pointer' }} />
            </button>

                                        </Box>
                                    </Box>
                                </form>
                            </div>
                        </Grid>
                    </Box>
          )}
          </Box>
                </Modal>
            </div>
        </div>
    );
}