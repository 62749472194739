import React, { useState } from 'react'
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

import close from "../../../assets/icons/close-icon.svg";
import { Grid, Typography, Box, Divider, Button } from '@mui/material';
import GovermentModalForm from './components/GovermentModalForm';
import BusinessModelForm from './components/BusinessModelForm.js';
import { Scrollbars } from "react-custom-scrollbars-2";
export default function HomepageModal(props) {
    const [openGoverment, setOpenGoverment] = useState(false);
    const handleClose = () => setOpenGoverment(false);

    const navigate = useNavigate();
    const [openBusinessForm, setOpenBusinessForm] = useState(false);
    console.log(props, "props value")

    // const RedirectLink = [
    //     {
    //         link:
    //     }
    // ]

    const onclose = () => {
        setOpenBusinessForm(false);
    }

    const style = {
        position: 'absolute',
        top: '53%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "40%",
        maxHeight: "81%",
        bgcolor: 'background.paper',
        border: "none",
        borderRadius: "5px",
        p: 2,
    };


    return (
        <div>
            <Modal
                open={props.open}
                onClose={false}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                // style={{ zIndex: 10000 }} 
                >

                <Box className="Home Homepage-popup">
                    <Grid container >
                        <Grid item xs={12} md={12} >
                            <Box sx={{ paddingBottom: "20px" }} >
                                <div style={{ float: "right", cursor: "pointer" }} onClick={props.close}>
                                    <img
                                        className='Home-page-pop-close '
                                        src={close}
                                        // height={20}
                                        // width={20}
                                        alt="close"

                                    />
                                </div>

                                <Box sx={{ display: "flex", justifyContent: "left" }}>
                                    <img height={40} width={41.5} src={props.data.image} />
                                    <Typography className="primary-font-medium-4" sx={{ marginLeft: "20.5px", mt: "8px" }}> {props.data.title}</Typography>
                                </Box>
                            </Box>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} md={12} className='laptop-home-popup-content' >
                        <Scrollbars style={{height:'385px'}}>
                            <Typography className='primary-font-normal-7' sx={{ paddingTop: "20px", paddingRight:'10px' }}>
                            <span dangerouslySetInnerHTML={{ __html: props.data.content ? props.data.content : "" }} />
                            </Typography>
                            </Scrollbars>
                            <Divider sx={{ my: "15px" }} />
                           
                        </Grid>
                        <Grid lg={12} xs={12} sm={12} className='mobile-home-popup-content'>
                            <Scrollbars style={{ height: "250px" }}>
                                <Typography
                                    sx={{ paddingTop: "10px", paddingRight: "20px", }}
                                    className="primary-font-normal-7"
                                >
                                    <span dangerouslySetInnerHTML={{ __html: props.data.content ? props.data.content : "" }} />
                                </Typography>
                            </Scrollbars>
                            <Divider sx={{ my: "15px" }} />
                        </Grid>

                        <Grid sx={{ marginX: "auto" }}>
                            <Button onClick={() => {
                                if (props.data.link) {
                                    navigate(props.data.link)
                                }
                                   if(props.data.key==="business"){
                                    setOpenBusinessForm(true);
                                   }
                                else {
                                    // props.close()
                                    setOpenGoverment(true)

                                }
                            }} className='primary-font-medium' sx={{
                                width: "170px",
                                height: "45px",
                                border: "1px solid #FF3535",
                                borderRadius: " 8px",
                                opacity: 1,
                                textTransform: "none",
                                color: " #FF3535",
                                "&:hover": {
                                    backgroundColor: "#ff3535",
                                    color: "#ffffff",
                                    borderColor: "#ff3535",
                                },

                            }}> Get started</Button>
                            {/* <BusinessModelForm onOpen={openBusinessForm} onClose={onclose}/> */}
                        </Grid>
                    </Grid>


                </Box>

            </Modal>
            {
                    openGoverment &&
                
<GovermentModalForm  onClose={handleClose}
        onOpen={openGoverment} close={props.close} />
                }
                {
                    openBusinessForm && 
                        <BusinessModelForm onOpen={openBusinessForm} onClose={onclose} close={props.close} /> 
                }
        </div>
    )
}
